import width from "../../sharedWidths.module.css";
import typography from "../../sharedTypography.module.css";
import classes from "./previewImages.module.css";
import data from "./data.json";

function PreviewImages(props) {
  const index = props.index;
  const imgNumber = [1, 2];
  return (
    <div className={`${classes.container} ${width.width}`}>
      <h2>Static Previews</h2>

      <div className={classes.layout}>
        {imgNumber.map((number, key) => (
          <div className={classes.imgContainer} key={key}>
            <picture>
              <source
                className={classes.image}
                media="(max-width: 760px)"
                srcSet={
                  window.location.origin +
                  `${data[index][`previewImage${number}`]["mobile"]}`
                }
              />
              <source
                className={classes.image}
                media="(max-width: 1399px)"
                srcSet={
                  window.location.origin +
                  `${data[index][`previewImage${number}`]["tablet"]}`
                }
              />
              <source
                className={classes.image}
                media="(max-width: 3000px)"
                srcSet={
                  window.location.origin +
                  `${data[index][`previewImage${number}`]["desktop"]}`
                }
              />

              <img
                className={classes.image}
                src={
                  window.location.origin +
                  `${data[index][`previewImage${number}`]["mobile"]}`
                }
                alt={"project preview"}
              />
            </picture>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PreviewImages;
