import width from "../../sharedWidths.module.css";
import typography from "../../sharedTypography.module.css";
import classes from "./pagination.module.css";
import leftArrow from "./assets/arrow-left.svg";
import rightArrow from "./assets/arrow-right.svg";
import data from "./data.json";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
// import React from "react";

function Pagination(props) {
  const params = useParams();
  const [previousPage, setPreviousPage] = useState(0);
  const [nextPage, setNextPage] = useState(0);

  const index = parseInt(params.id);

  const lastProject = Object.keys(data).length - 1;

  useEffect(() => {
    if (index === 0) {
      setPreviousPage(lastProject);
      setNextPage(index + 1);
    } else if (index === lastProject) {
      setNextPage(0);
      setPreviousPage(index - 1);
    } else {
      setPreviousPage(index - 1);
      setNextPage(index + 1);
    }
  }, [index]);

  return (
    <div className={`${classes.container} ${width.width}`}>
      <div className={classes.row}>
        <Link
          to={{ pathname: "/project/" + previousPage }}
          className={classes.left}
        >
          <div>
            <img src={leftArrow} alt = "" />

            <h3 className={typography.black100}>
              {data[previousPage]["name"]}
            </h3>

            <p className={`${typography.textContent16} ${typography.black50}`}>
              Previous Project
            </p>
          </div>
        </Link>

        <Link
          to={{ pathname: "/project/" + nextPage }}
          className={classes.right}
        >
          <img src={rightArrow} alt =""/>

          <h3 className={typography.black100}>{data[nextPage]["name"]}</h3>

          <p className={`${typography.textContent16} ${typography.black50}`}>
            Next Project
          </p>
        </Link>
      </div>
    </div>
  );
}

export default Pagination;
