import typography from "../../sharedTypography.module.css";
import linkStyle from "../../sharedLinkStyle.module.css";
import classes from "./projectIntro.module.css";
import data from "./data.json";

function ProjectIntro(props) {
  const index = props.index;

  return (
    <div>
      <div className={classes.projectOverview}>
        <h1 className={classes.projectName}>{data[index]["name"]}</h1>

        <div className={classes.rightCol}>
          <p className={typography.textContent15}>{data[index]["overview"]}</p>
        </div>

        <div className={classes.leftCol}>
          <p
            className={`${typography.textTypeAndSkills} ${typography.green100} ${classes.typeAndSkills}`}
          >
            {" "}
            {data[index]["type"]}
            <br />
            {data[index]["skills"]}
          </p>

          <a
            href={data[index]["website"]}
            target="#"
            className={`${typography.link} ${typography.black100}`}
          >
            <div className={`${linkStyle.linkStyle}`}>Visit Website </div>{" "}
          </a>

          <a
            href={data[index]["github"]}
            target="#"
            className={`${typography.link} ${typography.black100}`}
          >
            <div className={`${linkStyle.linkStyle}`}>Github</div>{" "}
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProjectIntro;
