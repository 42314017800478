import classes from "./footer.module.css"
import width from "../../sharedWidths.module.css";
import Logo from "./assets/logo.svg"
import GithubIcon from "./assets/github.svg"
import LinkedInIcon from "./assets/linkedin.svg"
import Menu from "../menu/menu";

function Footer(){

    


    return(<footer>

      <div className={`${classes.navBar} ${width.width}`}>

<div className={classes.logoContainer}>
          <img src={Logo} alt="Logo" />
        </div>

        <Menu/>

        <div className={classes.socialMedia}>
         <a target="_blank" rel="noreferrer" href = "https://github.com/MattBugeja"> <img src={GithubIcon} alt="Github Icon" /> </a>
         <a target="_blank" rel="noreferrer" href = "https://www.linkedin.com/in/matthew-bugeja-160181189" > <img src={LinkedInIcon} alt="LinkedIn Icon" /> </a>
        </div>
        </div>
    </footer>)
}


export default Footer