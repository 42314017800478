import classes from "./heroImage.module.css";
import data from "./data.json";

function HeroImage(props) {
  return (
    <picture>
      <source
        className={classes.image}
        media="(max-width: 760px)"
        srcSet={
          window.location.origin + `${data[props.index]["heroImage"]["mobile"]}`
        }
      />
      <source
        className={classes.image}
        media="(max-width: 1399px)"
        srcSet={
          window.location.origin + `${data[props.index]["heroImage"]["tablet"]}`
        }
      />
      <source
        className={classes.image}
        media="(max-width: 3000px)"
        srcSet={
          window.location.origin +
          `${data[props.index]["heroImage"]["desktop"]}`
        }
      />
      <img
        className={classes.image}
        src={
          window.location.origin + `${data[props.index]["heroImage"]["mobile"]}`
        }
      />
    </picture>
  );
}

export default HeroImage;
