import "./App.css";
import Home from "./components/home/home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProjectIndex from "./components/projects/projectIndex";
import ProjectDetails from "./components/projects/projectDetails";
import Footer from "./components/footer/footer";
import { ScrollToTop } from "react-router-scroll-to-top";
import NavMenu from "./components/nav/nav";
import Audiophile from "./components/audiophile/components/Audiophile";
import ContactMe from "./components/contactMe/contactMe";

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop>
          <NavMenu />
          <Switch>
            <Route path="/portfolio/" component={ProjectIndex}></Route>
            <Route path="/project/:id/" component={ProjectDetails}></Route>
            <Route path="/projects/audiophile" component={Audiophile}></Route>
            <Route path="/contactme" component={ContactMe}></Route>
            <Route path="/" component={Home}></Route>
          </Switch>
        </ScrollToTop>
     
        <Footer />
      </div>
    </Router>
  );
}

export default App;
