import width from "../../sharedWidths.module.css";
import typography from "../../sharedTypography.module.css";
import linkStyle from "../../sharedLinkStyle.module.css";
import classes from "./projectIndex.module.css";
import data from "./data.json";
import IndexImages from "./indexImages";
import { Link } from "react-router-dom";
import ContactMeLink from "../contactMeLink/contactMeLink";

const projects = ["Manage", "Bookmark", "Insure", "Fylo"];

function ProjectIndex() {
  return (
    <div className={`${width.width}`}>
      {projects.map((project, index) => (
        <div key={index}>
          <div
            className={` ${index % 2 === 0 ? classes.row : classes.reverseRow}`}
          >
            <div className={classes.imageContainer}>
              <IndexImages index={index} />
            </div>

            <section className={classes.projectDescription}>
              <h1>{data[index]["name"]}</h1>

              <p
                className={`${typography.textContent15} ${typography.black80}`}
              >
                {data[index]["overview"]}
              </p>

              <Link
                to={{ pathname: "/project/" + index }}
                className={`${typography.link} ${typography.black100}`}
              >
                <div className={`${linkStyle.linkStyle}`}>View Project</div>{" "}
              </Link>
            </section>
          </div>
        </div>
      ))}
      <ContactMeLink/>
    </div>
  );
}

export default ProjectIndex;
