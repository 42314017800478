import "./Audiophile.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ScrollToTop } from "react-router-scroll-to-top";
import { useState,} from "react";
import Cart from "./cart/Cart";
import CategoriesListed from "./categories/CategoriesListed";
import Checkout from "./checkout/Checkout";
import CounterContext from "./contexts/CounterContext";
import Footer from "./footer/Footer";
import Home from "./home/Home";
import NavMenu from "./nav/NavMenu";
import ProductDetails from "./productdetails/ProductDetails";

function Audiophile() {
  const [xx59Counter, setXx59Counter] = useState(0);
  const [xx99MK1Counter, setXx99MK1Counter] = useState(0);
  const [xx99MK2Counter, setXx99MK2Counter] = useState(0);
  const [yx1Counter, setYx1Counter] = useState(0);
  const [zx7Counter, setZx7Counter] = useState(0);
  const [zx9Counter, setZx9Counter] = useState(0);

  const setValue = [
    setXx59Counter,
    setXx99MK1Counter,
    setXx99MK2Counter,
    setYx1Counter,
    setZx7Counter,
    setZx9Counter,
  ];
  const readValue = [
    xx59Counter,
    xx99MK1Counter,
    xx99MK2Counter,
    yx1Counter,
    zx7Counter,
    zx9Counter,
  ];

  return (
    <CounterContext.Provider value={[setValue, readValue]}>
      <Router basename="/" >
        <div className="audiophile">

    
          <ScrollToTop>
            <NavMenu />
            <Switch>

            <Route exact path="/projects/audiophile/" component = {Home}>
              </Route>
              <Route exact path="/projects/audiophile/checkout/">
                <Checkout />
              </Route>

              <Route path="/projects/audiophile/product/:id" component = {ProductDetails}>
              </Route>

              <Route path="/projects/audiophile/category/:id" component = {CategoriesListed} >
              </Route>

             
            </Switch>
          </ScrollToTop>
    
          <Footer />
        </div>
      </Router>
    </CounterContext.Provider>
  );
}

export default Audiophile;
