import width from "../../sharedWidths.module.css";
import classes from "./projectDetails.module.css";
import PreviewImages from "./previewImages";
import ProjectBackground from "./projectBackground";
import ProjectIntro from "./projectIntro";
import { useParams } from "react-router";
import Pagination from "./pagination";
import HeroImage from "./heroImage";
import ContactMeLink from "../contactMeLink/contactMeLink";

function ProjectDetails(props) {
  const index = useParams().id;

  return (
    <section className={`${width.width} ${"project"}`}>
      <HeroImage index={index} />

      <div className={classes.row}>
        <ProjectIntro index={index} />
        <ProjectBackground index={index} />
      </div>
      <PreviewImages index={index} />
      <Pagination index={index} />
      <ContactMeLink/>
    </section>
  );
}

export default ProjectDetails;
