import { Link } from "react-router-dom";
import classes from "./contactMeLink.module.css";
import width from "../../sharedWidths.module.css";
import typography from "../../sharedTypography.module.css";
import linkStyle from "../../sharedLinkStyle.module.css";

function ContactMeLink({ children }) {
  return (
    <section className={`${classes.contactMeLink} ${width.width}`}>
      <h1 className={`${width.width} ${classes.textContent}`}>
        Like what you see?
      </h1>

      <Link
        to={{ pathname: "/contactme/" }}
        className={`${typography.link} ${typography.black100}`}
      >
        <div className={`${linkStyle.linkStyle} ${classes.linkPosition}`}>
          contact me
        </div>{" "}
      </Link>
    </section>
  );
}

export default ContactMeLink;
