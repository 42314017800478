import width from "../../sharedWidths.module.css";

import data from "./data.json";

function IndexImages(props) {
  return (
    <picture>
      <source
        className={width.image}
        media="(max-width: 760px)"
        srcSet={
          window.location.origin +
          `${data[props.index]["overviewImage"]["mobile"]}`
        }
      />
      <source
        className={width.image}
        media="(max-width: 1399px)"
        srcSet={
          window.location.origin +
          `${data[props.index]["overviewImage"]["tablet"]}`
        }
      />
      <source
        className={width.image}
        media="(max-width: 3000px)"
        srcSet={
          window.location.origin +
          `${data[props.index]["overviewImage"]["desktop"]}`
        }
      />
      <img
        className={width.image}
        src={
          window.location.origin +
          `${data[props.index]["overviewImage"]["mobile"]}`
        }
      />
    </picture>
  );
}

export default IndexImages;
