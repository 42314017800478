import classes from "./home.module.css";
import typography from "../../sharedTypography.module.css";
import linkStyle from "../../sharedLinkStyle.module.css";
import width from "../../sharedWidths.module.css";
import ContactMeLink from "../contactMeLink/contactMeLink";
import { Link } from "react-router-dom";
import Images from "./Images";

function Home() {
  return (
    <div className={width.width}>
      <div className={classes.heroImage}>
        <Images type="hero" />

        <h1 className={`${classes.introText}`}>
          Hi, I'm Matthew Bugeja, software developer and perpetual student.
        </h1>
      </div>

      <div className={classes.row}>
        <div className={classes.profileImg}>
          <Images type="profile" />
        </div>

        <section className={`${classes.aboutMe}`}>
          <h1>About Me</h1>

          <p className={`${typography.textContent16} ${width.width}`}>
            I’m a front-end developer. My focus so far has been on HTML5, modern CSS (styled-components, modular css, and Tailwind),
            JavaScript, and React. I'm also pretty comfortable using Python and
            a number of its libraries.
            <br />
            <br /> I learn new stuff as a hobby and can pick up new skills
            quickly, so I can adapt to whatever tools and languages are needed,
            pretty much on the fly.
            <br />
            <br />
            I'm a firm believer in the philosophy that one should be continously
            improving, and aiming to be better tomorrow than today.
            <br />
            <br /> When I’m not coding, I’m either out with my dogs, following
            courses on something that caught my interest, or out paddling on my
            SUP.
          </p>

          <Link
            to={{ pathname: "/portfolio/" }}
            className={`${typography.link} ${typography.black100}`}
          >
            <div className={`${linkStyle.linkStyle}`}>Go to Portfolio</div>{" "}
          </Link>
        </section>
      </div>
      <ContactMeLink/>
    </div>
  );
}

export default Home;
