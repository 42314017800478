import width from "../../sharedWidths.module.css";
import typography from "../../sharedTypography.module.css";
import classes from "./contactMe.module.css";
import { ReactComponent as GithubIcon } from "./assets/github.svg";
import { ReactComponent as LinkedInIcon } from "./assets/linkedin.svg";

function ContactMe() {
  return (
    <div className={`${classes.container} ${width.width}`}>
      <div className={classes.desktopLayout}>
        <h1 className={classes.title}>Get in Touch</h1>
        <p className={`${classes.content} ${typography.textContent15}`}>
         I'm always open to chat about Tech related stuff. I'm a
          hard-working, driven person who approaches all tasks with a sense of
          ownership, innovation, and an overall philosophy that decisions taken
          should be sustainable and make tomorrow's work easier.
        </p>

        <div className={classes.contactInfo}>
          <p className={typography.textContent15}>
            Feel free to checkout my social media on :
            <ul className={`${classes.row} ${classes.socialMedia}`}>
              <li>
                <GithubIcon />
              </li>{" "}
              <li>
                <LinkedInIcon />
              </li>
            </ul>
          </p>

          <p className={typography.textContent15}>
            Or drop me a line on:
            <ul>
              <li>e-mail: hello@mattbugeja.dev</li>
              <li>Mobile: 79354256</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContactMe;
