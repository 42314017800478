import heroImgDesktop from "./assets/heroImgDesktop.jpg";
import heroImgTablet from "./assets/heroImgTablet.jpg";
import heroImgMobile from "./assets/heroImgMobile.jpg";
import profileImgDesktop from "./assets/profile-desktop.jpg";
import profileImgTablet from "./assets/profile-tablet.jpg";
import profileImgMobile from "./assets/profile-mobile.png";
import { useState, useEffect } from "react";
import width from "../../sharedWidths.module.css";

function Images(props) {
  const [imgType, setImageType] = useState({});

  const profile = {
    mobile: profileImgMobile,
    tablet: profileImgTablet,
    desktop: profileImgDesktop,
  };

  const hero = {
    mobile: heroImgMobile,
    tablet: heroImgTablet,
    desktop: heroImgDesktop,
  };

  useEffect(() => {
    if (props.type === "profile") {
      setImageType(profile);
    } else if (props.type === "hero") {
      setImageType(hero);
    }
  }, []);

  return (
    <picture>
      <source
        className={width.image}
        media="(max-width: 760px)"
        srcSet={imgType.mobile}
      />
      <source
        className={width.image}
        media="(max-width: 1399px)"
        srcSet={imgType.tablet}
      />
      <source
        className={width.image}
        media="(max-width: 3000px)"
        srcSet={imgType.desktop}
      />
      <img className={width.image} src={imgType.mobile} alt="" />
    </picture>
  );
}

export default Images;
