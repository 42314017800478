import typography from "../../sharedTypography.module.css";
import classes from "./projectBackground.module.css";
import data from "./data.json";

function ProjectBackground(props) {
  const index = props.index;
  return (
    <div className={`${classes.container}`}>
      <h2>Project Background</h2>

      <p className={typography.textContent15}>{data[index]["background"]}</p>
    </div>
  );
}

export default ProjectBackground;
