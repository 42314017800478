import React from "react";
import { useState } from "react";
import { ReactComponent as CloseMenu } from "./assets/close.svg";
import { ReactComponent as MenuIcon } from "./assets/hamburger.svg";
import logo from "./assets/logo3.svg";
import classes from "./nav.module.css";
import width from "../../sharedWidths.module.css";
import { Link } from "react-router-dom";
import typography from "../../sharedTypography.module.css";

function NavMenu() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <header className={`${width.width} ${classes.navBar}`}>
      <nav className={classes.logoNav}>
        <Link to={"/"}>
          <div className={classes.logoContainer}>
            <img src={logo} className={classes.logo} />
          </div>
        </Link>

        <ul
          className={
            click
              ? `${classes.navOptions} ${classes.active} `
              : `${classes.navOptions}`
          }
        >
          <li className={classes.option} onClick={closeMobileMenu}>
            <Link
              to={{
                pathname: `/`,
              }}
              className={`${typography.link} ${typography.navBarColor}`}
            >
              home
            </Link>
          </li>

          <li className={classes.option} onClick={closeMobileMenu}>
            <Link
              to={{
                pathname: `/portfolio`,
              }}
              className={`${typography.link} ${typography.navBarColor}`}
            >
              portfolio
            </Link>
          </li>
          <li className={classes.option} onClick={closeMobileMenu}>
            <Link
              to={{
                pathname: `/contactme`,
              }}
              className={`${typography.link} ${typography.navBarColor}`}
            >
              contact
            </Link>
          </li>
        </ul>

        <div className={classes.mobileMenu} onClick={handleClick}>
          {click ? (
            <CloseMenu className={classes.menuIcon} />
          ) : (
            <MenuIcon className={classes.menuIcon} />
          )}
        </div>
      </nav>
    </header>
  );
}

export default NavMenu;
