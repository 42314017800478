import classes from "./menu.module.css";
import typography from "../../sharedTypography.module.css";
import { Link } from "react-router-dom";

function Menu() {
  const menuItems = ["portfolio", "contact me"];
  return (
    <nav>
      <ul className={classes.menuItems}>
        <li>
          <Link
            to={"/"}
            className={`${typography.link} ${typography.white100}`}
          >
            Home
          </Link>
        </li>

        {menuItems.map((item, index) => (
          <li key={index}>
            <Link
              to={{
                pathname: `/` + item.replace(/\s/g, ""),
              }}
              className={`${typography.link} ${typography.white100}`}
            >
              {item}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Menu;
